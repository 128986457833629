import React from 'react';
import './App.css';
import Blog from './Blog'
import IndividualBlogPost from './IndividualBlogPost'
import { Route, Switch } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/the_idea_of_you" component={IndividualBlogPost} />
        <Route path="/" component={Blog} />

        {/* <Route component={NoMatch} /> */}
      </Switch>
    </div>
  );
}

export default App;
