import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Instagram from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import Header from './Header';
import MainFeaturedPost from './MainFeaturedPost';
import FeaturedPost from './FeaturedPost';
import Main from './Main';
import Sidebar from './Sidebar';
import Footer from './Footer';
import post1 from './blog-post.1.md';
import post2 from './blog-post.2.md';
import post3 from './blog-post.3.md';
import raw from 'raw.macro';

const markdown = raw('./blog-post.1.md');

const useStyles = makeStyles((theme) => ({
  mainGrid: {
    marginTop: theme.spacing(3),
  },
}));

const sections = [
  { title: 'Technology', url: '#' },
  { title: 'Design', url: '#' },
  { title: 'Culture', url: '#' },
  { title: 'Business', url: '#' },
  { title: 'Politics', url: '#' },
  { title: 'Opinion', url: '#' },
  { title: 'Science', url: '#' },
  { title: 'Health', url: '#' },
  { title: 'Style', url: '#' },
  { title: 'Travel', url: '#' },
];

const mainFeaturedPost = {
  title: 'Existential Content For Troubled Beings',
  description:
    "Just another philosophy blog. Say no to philosophy. Noo don't kill yourself, you're too late anyway.",
  image: 'https://res.cloudinary.com/doefdz9w7/image/upload/v1598313874/Philosophy/3otlpi6wgyi51.jpg',
  imgText: 'main image description',
  linkText: '',
};

const featuredPosts = [
  {
    title: 'The Idea of You',
    date: 'Aug 22',
    description:
      `What defines you? Is your existence a reality or a subconciously constructed notion? What exists? You or the idea of "You" ? `,
    image: 'https://i.redd.it/2qsct3q7lse51.jpg',
    imageText: 'Save Me Im crying',
    link:'/the_idea_of_you',
    linkText: 'Continue Reading..'
  },
  {
    title: 'Death is Cool',
    date: 'Aug 30',
    description:
      'This is a upcoming post defining the purpose of death and why it is cool to die. Path to liberation and moksha from your sins.',
    image: 'https://i.redd.it/ux6sryy73hh51.jpg',
    imageText: 'Image Text',
    link:'',
    linkText: 'Coming Soon..'
  },
];

const posts = [markdown, post2, post3];

const sidebar = {
  title: 'About Me',
  description:
    '🤖Rahul P👾 🧑‍🎓Massive Twat 🎹Big Virgin Energy 🗣 Terrible Philospher and an incel.👑 I write about stuff, sometimes.🤖ॐ राहुल ॐ',
  archives: [
    { title: 'August 2020', url: '#' },
    { title: 'September 2020', url: '#' },
  ],
  social: [
    { name: 'Twitter', icon: TwitterIcon,link: 'https://twitter.com/technol0geek' },
    { name: 'Instagram', icon: Instagram,link : 'https://www.instagram.com/certified_awesomenes/'},
  ],
};

export default function Blog(props) {
  const classes = useStyles();
  return (
    <React.Fragment>
      <CssBaseline />
      <Container maxWidth="lg">
        <Header title="Suicidal Content : Readers Discretion Advised 🖤" sections={sections} />
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} link={post.link} linkText={post.linkText}/>
            ))}
          </Grid>
          <Grid container spacing={5} className={classes.mainGrid}>
            <Main title="The Idea of You" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
        </main>
      </Container>
      <Footer title="..And if you made it till here" description="This is the end, beautiful friend" />
    </React.Fragment>
  );
}
