/* eslint-disable jsx-a11y/accessible-emoji */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Typography from '@material-ui/core/Typography';
import Links from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '@media (max-width:600px)' : {
      display : "flex",
      flexDirection: "column"
    }
  },
  toolbarTitle: {
    flex: 1,
    borderBottom: `1px solid ${theme.palette.divider}`,
    '@media (max-width:600px)' : {
      whiteSpace:" break-spaces",
    }
  },
  toolbarSecondary: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },

}));

export default function Header(props) {
  const classes = useStyles();
  const { sections, title } = props;
  console.log(title)

  return (
    <React.Fragment>
      <Toolbar className={classes.toolbar}>
      <Button variant="outlined" size="small">
       <Links href="/">🏡 :Homepage:</Links>
        </Button>
        <Typography
          component="h2"
          variant="h5"
          color="inherit"
          align="center"
          noWrap
          className={classes.toolbarTitle}
        >
          {title}
        </Typography>
        😩
        <Button variant="outlined" size="small">
        <span>🙊 :speak_no_evil:</span>
        </Button>
      </Toolbar>
      <Toolbar component="nav" variant="dense" className={classes.toolbarSecondary}>
      <blockquote>
    <p>If I take death into my life, acknowledge it, and face it squarely, I will free myself from the anxiety of death and the pettiness of life - and only then will I be free to become myself.</p>
    <footer>—Martin Heidegger</footer>
</blockquote>

      <span></span>
      </Toolbar>
    </React.Fragment>
  );
}

Header.propTypes = {
  sections: PropTypes.array,
  title: PropTypes.string,
};
